import React from 'react'
import './style.less';
import Link from "../Link";
import Img from "gatsby-image";
import ReactHtmlParser from 'react-html-parser';

class BlogListItem extends React.Component {

    render(){

        return (
            <Link to={"/blog/"+this.props.post.slug+"/"} className="blog-listing-item">
                <span className="date">{this.props.post.date}</span>
                { this.props.post.acf.featured_image != null ?
                    <Img fluid={this.props.post.acf.featured_image.localFile.childImageSharp.fluid} />
                    : null }
                <span className="title">{ReactHtmlParser(this.props.post.title)}</span>
                <span className="arrow-link">Read more</span>
            </Link>
        );
    }
}

export default BlogListItem