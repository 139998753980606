import React, {Component} from 'react'
import Wrapper from '../components/Wrapper';
import Logos from "../components/Logos/index";
import HomeBanner from "../components/HomeBanner/index";
import BlogGrid from "../modules/BlogGrid/index";
import PhotoIntro from "../components/PhotoIntro/index";
import FeaturedWork from "../modules/FeaturedWork/index";
import StorytellersLarge from "../components/StoyrtellersLarge/index";
import { graphql } from 'gatsby';

class Index extends Component {
    render() {

        const page = this.props.data.wordpressPage;
        const acfFields = this.props.data.wordpressPage.acf;

        let seoTitle = acfFields.meta_title;
        let seoDescription = acfFields.meta_description;
        if (seoTitle===''){
            seoTitle = page.title;
        }

        let path = this.props.data.wordpressPage.slug;
        if (path === 'home') path = '';
        // add a trailing slash
        if (path.substr(-1) !== '/' && path !== '') path += '/';

        return (
            <Wrapper
                title={seoTitle}
                description={seoDescription}
                og_title={acfFields.og_title}
                og_description={acfFields.og_description}
                og_image={acfFields.og_image ? acfFields.og_image.localFile.childImageSharp.original.src : null}
                twitter_card_type={acfFields.twitter_card_type}
                twitter_title={acfFields.twitter_title}
                twitter_description={acfFields.twitter_description}
                twitter_image={acfFields.twitter_image ? acfFields.twitter_image.localFile.childImageSharp.original.src : null}
                path={`/${path}`}
            >
                <HomeBanner
                    content={page.acf.banner_section}
                />
                <PhotoIntro
                    blurb={page.acf.about_blurb}
                    images={page.acf.blurb_images}/>
                <Logos
                    logos={page.acf.client_logos}
                />
                <FeaturedWork/>
                <StorytellersLarge
                    content={page.acf.storytellers_section}
                />
                <BlogGrid/>
            </Wrapper>
        )
    }
}

export const pageQuery = graphql`
  query($id: String!) {
    wordpressPage(id: { eq: $id }) {
      id
      slug
      link
      wordpress_id
      wordpress_parent
      title
      acf {
        banner_section {
          heading
          strapline
          vimeo_video_id
          cta_text
          cta_link
        }
        about_blurb
        blurb_images {
          align_image
          portrait_image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 440, maxHeight:560, quality: 90, cropFocus: CENTER) {
                  ... GatsbyImageSharpFluid
                }
              }
            }
          }
          landscape_image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 790, maxHeight:490, quality: 90, cropFocus: CENTER) {
                  ... GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        client_logos {
          id
          localFile {
            childImageSharp {
              fluid(maxWidth: 175, maxHeight:90, quality: 90, cropFocus: CENTER) {
                ... GatsbyImageSharpFluid
              }
            }
          }
        }
        storytellers_section {
          background_image {
            localFile {
              childImageSharp {
                original {
                  width
                  height
                  src
                }
              }
            }
          }
          heading
          blurb
          cta_text
          cta_link
        }
        meta_title
        meta_description
		og_title
        og_description
        og_image {
          localFile {
            childImageSharp {
              original {
                width
                height
                src
              }
            }
          }  
        }
        twitter_title
        twitter_card_type
        twitter_description
        twitter_image {
          localFile {
            childImageSharp {
              original {
                width
                height
                src
              }
            }
          }  
        } 
      }
    }
  }
`

export default Index
