import React from 'react'
import './style.less';
import { Row, Col } from 'antd';
import {Link} from "@reach/router";

class HomeBanner extends React.Component {

    render(){

        return (
            <Row type="flex" className="home-banner">
                <Col xs={{span: 24}} md={{span:10}} lg={{span:8}}>
                    <h1>{this.props.content.heading}</h1>
                    <p className="strapline">{this.props.content.strapline}</p>
                </Col>
                <Col xs={{span: 22}} md={{span:13}} lg={{span:15}}>
                    <div className="responsive-video">
                        <iframe title="home-banner-video" src={"https://player.vimeo.com/video/"+this.props.content.vimeo_video_id+"?title=0&byline=0&portrait=0&autoplay=1&autopause=0&muted=1&background=1"} frameBorder="0"></iframe>
                    </div>
                    <p className="text-right">
                        <Link to={this.props.content.cta_link} className="btn btn-primary">
                            <span>{this.props.content.cta_text}</span>
                        </Link>
                    </p>
                </Col>
            </Row>
        );
    }
}

export default HomeBanner